/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import xss from 'xss'

import Container from '../../components/Container/Container'
import Hero from '../../components/Hero/Hero'
import Layout from '../../components/Layout/Layout'
import LinkWithChevron from '../../components/LinkWithChevron/LinkWithChevron'
import SEO from '../../components/SEO/SEO'
import Section from '../../components/Section/Section'

import './index.css'

const RESOURCE_SORT_TYPES = {
  AZ: 'AZ',
  ORGANIZATIONS: 'ORGANIZATIONS',
  EVENTS: 'EVENTS',
}

const RESOURCE_SORT_TYPES_TITLES = {
  AZ: 'A-Z',
  ORGANIZATIONS: 'Organizations',
  EVENTS: 'Events',
}

const ResourcePage = ({
  data: {
    resourcesData: { edges: resources },
  }
}) => {
  const [activeSortType, setActiveSortType] = useState(RESOURCE_SORT_TYPES.AZ)
  const [sortedResources, setSortedResources] = useState([])

  useEffect(() => {
    const _resources = resources.sort((a, b) => a.node.frontmatter.title.localeCompare(b.node.frontmatter.title))
    if (activeSortType === RESOURCE_SORT_TYPES.AZ) {
      setSortedResources(_resources)
    } else {
      setSortedResources(_resources.filter(({ node: { frontmatter: { type } } }) => type === activeSortType))
    }
  }, [activeSortType, resources])

  return (
    <Layout className="Resources">
      <SEO title="Resources" />
      <Hero title="Resources" />
      <Section>
        <Container>
          <div className="Resources--filters">
            <strong>Filter by:</strong>
            {Object.keys(RESOURCE_SORT_TYPES).map(resourceType => (
              <label
                key={resourceType}
                htmlFor={`radio-${resourceType}`}
              >
                <input
                  checked={activeSortType === resourceType}
                  id={`radio-${resourceType}`}
                  onChange={() => setActiveSortType(resourceType)}
                  name="filter"
                  type="radio"
                  value={resourceType}
                />
                {RESOURCE_SORT_TYPES_TITLES[resourceType]}
              </label>
            ))}
          </div>
          <div className="Resources--resource-grid">
            {sortedResources.map(({ node: { frontmatter } }) => (
              <div key={frontmatter.title} className="Resources--resource">
                <h4>{frontmatter.title}</h4>
                <p className="font-small" dangerouslySetInnerHTML={{ __html: xss(frontmatter.content) }} />
                <LinkWithChevron href={frontmatter.url}>Visit Site</LinkWithChevron>
              </div>
            ))}
          </div>
          <div className="Resources--remove">
            <p>To add or remove your platform from the Resources page, please email <a href="mailto:info@melanin.tech">info@melanin.tech</a></p>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ResourcePageQuery {
    resourcesData: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/\/resources\//"}}) {
      edges {
        node {
          frontmatter {
            content
            title
            type
            url
          }
        }
      }
    }
  }
`

export default ResourcePage
